import classNames from 'classnames';
import * as React from 'react';

import { IWRichTextProps } from '../WRichText.types';
import { TestIds } from '../constants';
import { getQaDataAttributes } from '../../../core/commons/qaUtils';
import { getAriaAttributes } from '../../../core/commons/a11y';
import skinsStyle from './style/WRichText.scss';
import { usePopupLinkEvents } from './providers/usePopupLinkEvents';

const WRichText: React.FC<IWRichTextProps> = props => {
  const {
    id,
    html,
    skin = 'WRichTextSkin',
    a11y,
    isQaMode,
    fullNameCompType,
    ariaAttributes,
  } = props;

  const htmlWrapperRef = React.useRef<HTMLDivElement>(null);

  usePopupLinkEvents(htmlWrapperRef, [html]);

  const skinsWithContainer: Array<IWRichTextProps['skin']> = [
    'WRichTextSkin',
    'WRichTextClickableSkin',
  ];
  const isInContainer = skinsWithContainer.includes(skin);

  const sdkEventHandlers = {
    onMouseEnter: props.onMouseEnter,
    onMouseLeave: props.onMouseLeave,
    onClick: props.onClick,
    onDoubleClick: props.onDblClick,
  };

  const rootStyles = classNames(skinsStyle[skin], {
    [skinsStyle.clickable]: props.onClick || props.onDblClick,
  });

  const getWrapperAttributes = () => ({
    id,
    className: rootStyles,
    'data-testid': TestIds.richTextElement,
    ...sdkEventHandlers,
    ...a11y,
    ...getQaDataAttributes(isQaMode, fullNameCompType),
    ...getAriaAttributes(ariaAttributes),
  });

  return isInContainer ? (
    <div {...getWrapperAttributes()}>
      <div
        className={skinsStyle.richTextContainer}
        dangerouslySetInnerHTML={{ __html: html }}
        data-testid={TestIds.containerElement}
        ref={htmlWrapperRef}
      />
    </div>
  ) : (
    <div
      {...getWrapperAttributes()}
      dangerouslySetInnerHTML={{ __html: html }}
      ref={htmlWrapperRef}
    />
  );
};

export default WRichText;
